import React, { useEffect, useRef, useState } from "react";
import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AddWorkoutPopup from "./AddWorkoutPopup";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import EditWorkoutPopup from "./EditWorkoutPopup";
import { IconButton, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  add_program_workout_api,
  delete_program_workout_Api,
  delete_program_workout_details_Api,
  paste_multiple_workouts,
  programme_workout_list_api,
  reorder_events_api,
  update_program_workout_api,
} from "src/DAL/programmeWorkout/programmeWorkout";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmationWithDescription from "src/components/ModalPopover/ConfirmationWithDescription";
import moment from "moment";
import { Icon } from "@iconify/react";
import { s3baseUrl } from "src/config/config";
import { makeStyles } from "@mui/styles";
import TextEditor from "src/components/GeneralComponents/GeneralQuestions/TextEditor";
import { addNotes, getNotes } from "src/DAL/notes";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const INITIAL_VALUES = {
  title: "",
  description: "",
  categories: [],
  status: true,
  image: null,
  imagePreview: null,
  videoURL: "",
};

const DynamicTable = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const [notes, setNotes] = useState([]);

  const { slug } = useParams();
  const classes = useStyles();

  const Table = ({ numRows, numCols }) => {
    const [hoveredCell, setHoveredCell] = useState(null);
    const [drawerState, setDrawerState] = useState(false);
    const [editItrationDrawerState, setEditItrationDrawerState] = useState();
    const { enqueueSnackbar } = useSnackbar();
    const [editWorkputData, setEditWorkputData] = useState();
    const [dataDelMember, setDataDelMember] = useState();
    const [openDelete, setOpenDelete] = useState(false);
    const [selectedDate, setSelectDate] = useState("");
    const [dataDel, setDataDel] = useState();
    const [workouts, setworkouts] = useState([]);
    const [dataCopy, setDataCopy] = useState();
    const [effectedMembers, setEffectedMembers] = useState(state?.members);
    const [pasteDate, setPasteDate] = useState();
    const [sortedIds, setSortedIds] = useState([]);
    const [selectedWorkouts, setSelectedWorkouts] = useState([]);
    const [pastedWorkouts, setPastedWorkouts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const calendarRef = useRef(null);
    const [tableData, setTableData] = useState(
      Array(numRows).fill(Array(numCols).fill(""))
    );
    const [openDragConfig, setOpenDragConfig] = useState(false);
    const [DragConfigDate, setDragConfigDate] = useState(false);
    const [DragConfigData, setDragConfigData] = useState(false);
    const [openCopyPasteConfig, setOpenCopyPasteConfig] = useState(false);
    const [inputs, setInputs] = useState(INITIAL_VALUES);

    const [open, setOpen] = useState(false); // State to control dialog visibility

    const handleOpen = () => {
      setOpen(true); // Open the dialog
    };

    const handleClose = () => {
      setOpen(false); // Close the dialog
    };

    const getNotesList = async () => {
      const result = await getNotes(state._id);

      if (result.code === 200) {
        if (result.data) {
          setInputs((oldInputs) => ({
            ...oldInputs,
            description: result.data.content,
          }));
        }
      } else {
        enqueueSnackbar(result.message || "Failed to add the note", {
          variant: "error",
        });
      }
    };

    useEffect(() => {
      getNotesList();
    }, []);

    const handleAddNote = async () => {
      const postData = {
        content: inputs.description,
        programId: state._id,
      };
      const result = await addNotes(postData);

      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        getNotesList();
        handleClose();
      } else {
        enqueueSnackbar(result.message || "Failed to add the note", {
          variant: "error",
        });
      }
    };

    const handleCellHover = (rowIndex, colIndex) => {
      setHoveredCell({ rowIndex, colIndex });
    };
    const handleCellLeave = () => {
      setHoveredCell(null);
    };
    const handleAgreeDelete = async (data) => {
      // setPastedWorkouts([]);
      // setSelectedWorkouts([]);
      setDataDel(data);
      setOpenDelete(true);
      // const result = await delete_program_workout_details_Api(data?._id);
      // if (result.code === 200) {
      //   setDataDelMember(result.members_count);

      // } else {
      //   enqueueSnackbar(result.message, { variant: "error" });
      // }
    };
    const handleDeleteEvent = (eventToDelete) => {
      // Handle deletion logic here
    };
    const handleDelete = async (value) => {
      setOpenDelete(false);

      const result = await delete_program_workout_Api(
        dataDel?.program_workout_slug
      );
      if (result.code === 200) {
        setEditWorkputData();

        enqueueSnackbar(result.message, { variant: "success" });
        let updatedEvents = workouts.filter(
          (item) => item.program_workout_slug != dataDel.program_workout_slug
        );
        setworkouts(updatedEvents);
        // getWorkoutListing();
        setPastedWorkouts([]);
        setSelectedWorkouts([]);
        setDataCopy(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    };
    const handleChange = (rowIndex, colIndex, value) => {
      const newData = tableData.map((row, i) =>
        i === rowIndex
          ? row.map((col, j) => (j === colIndex ? value : col))
          : row
      );
      setTableData(newData);
    };
    const handleOpenDrawer = (week, day) => {
      // setPastedWorkouts([]);
      // setSelectedWorkouts([]);
      let date = (week - 1) * 7 + day;
      setSelectDate(date);
      setDrawerState(true);
    };
    const handleCopyExercise = (data, rowIndex, colIndex) => {
      setEditWorkputData();
      setPastedWorkouts();
      setSelectedWorkouts((prevSelected) => {
        const exists = prevSelected.some((workout) => workout._id === data._id);

        const updatedSelected = exists
          ? prevSelected.filter((workout) => workout._id !== data._id)
          : [...prevSelected, { ...data, rowIndex, colIndex }];

        setDataCopy(updatedSelected.length > 0);

        const sortedSelected = updatedSelected.sort((a, b) => {
          if (a.rowIndex !== b.rowIndex) {
            return a.rowIndex - b.rowIndex;
          } else {
            return a.colIndex - b.colIndex;
          }
        });

        return sortedSelected;
      });
    };
    const calculateIntervals = (days) => {
      const intervals = [];
      for (let i = 1; i < days.length; i++) {
        const interval = days[i] - days[i - 1];
        intervals.push(interval);
      }
      return intervals;
    };
    function generateDates(intervals, pastedDay, selected) {
      const result = [];
      let currentDay = pastedDay;

      for (let i = 0; i < selected.length; i++) {
        if (i === 0) {
          result.push(currentDay);
        } else {
          const interval = intervals[i - 1] || 0; // Use the previous interval or 0
          currentDay += interval;
          result.push(currentDay);
        }
      }

      return result;
    }

    // Highlight if workout is selected
    const isWorkoutSelected = (workout) =>
      selectedWorkouts.some((item) => item._id === workout._id) ||
      (pastedWorkouts &&
        pastedWorkouts.some((item) => item._id === workout._id));

    const handlePaste = async (week, day) => {
      const pasted = (week - 1) * 7 + day;
      const dates =
        selectedWorkouts &&
        selectedWorkouts.map((workout) => workout.workout_day);
      const sorthedDates = dates.sort((a, b) => a - b);
      const intervalsAll = calculateIntervals(sorthedDates);
      const datesToBePasted = generateDates(intervalsAll, pasted, sorthedDates);

      const hasError = datesToBePasted.some(
        (day) => day > state?.no_of_weeks * 7
      );
      if (hasError) {
        enqueueSnackbar(
          "Pasting is not allowed on dates beyond the calendar's range",
          {
            variant: "error",
          }
        );
        setPastedWorkouts([]);
        setSelectedWorkouts([]);
        setDataCopy(false);
      } else {
        setPasteDate((week - 1) * 7 + day);
        setOpenCopyPasteConfig(true);
      }
    };
    const handleOpenEditIterationDrawer = () => {
      setEditItrationDrawerState(true);
    };
    const handleUpdateIteration = (data) => {
      setEditWorkputData(data);
      setSelectDate(data?.workout_day);
      handleOpenEditIterationDrawer();
    };
    const handleReorder = async (newevents) => {
      // const ids = newevents.map((event) => event._id);
      let postData = {
        workoutIds: newevents,
      };
      const result = await reorder_events_api(postData);
      if (result.code === 200) {
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    };
    const EventCard = ({
      data,
      setData,
      index,
      onDelete,
      onHover,
      rowIndex,
      colIndex,
    }) => {
      const [isHovered, setIsHovered] = useState(false);

      const timeoutRef = useRef(null);

      useEffect(() => {
        // Cleanup function to clear the timeout on component unmount
        return () => {
          clearTimeout(timeoutRef.current);
        };
      }, []);
      const formatWithLeadingZeros = (value) => {
        return value < 10 ? `0${value}` : `${value}`;
      };
      const getMinutesForInterval = (mainindex, index) => {
        const list = [...data?.exercises];
        const interval = list[mainindex].interval_duration;
        if (interval <= 60) {
          return index + 1;
        }
        if (interval % 60 == 0) {
          return `${
            (interval / 60) * index +
            1 +
            " – " +
            ((interval / 60) * index + interval / 60)
          }`;
        }
        let startInterval = interval * index;
        let endtInterval = interval * index + interval;
        let startintervalText =
          interval * index
            ? Math.floor(startInterval / 60) +
              " " +
              ":" +
              formatWithLeadingZeros(startInterval % 60)
            : 0;
        let endIntervalText =
          Math.floor(endtInterval / 60) +
          " " +
          ":" +
          formatWithLeadingZeros(endtInterval % 60);

        return `${startintervalText + " – " + endIntervalText}`;
      };
      const handleMouseEnter = () => {
        clearTimeout(timeoutRef.current); // Clear any existing timeout
        setIsHovered(true);
      };

      const isFirstEventOnDate = (workout) => {
        const sameDayWorkouts = workouts.filter(
          (w) => w.workout_day === workout.workout_day
        );
        return sameDayWorkouts[0]._id === workout._id;
      };

      const isLastEventOnDate = (workout) => {
        const sameDayWorkouts = workouts.filter(
          (w) => w.workout_day === workout.workout_day
        );
        return sameDayWorkouts[sameDayWorkouts.length - 1]._id === workout._id;
      };
      const updateSortedIds = (workoutDay, updatedWorkouts) => {
        const sortedIds = updatedWorkouts
          .filter((w) => w.workout_day === workoutDay)
          .map((w) => w._id);
        setSortedIds(sortedIds);
        console.log("Updated sorted IDs:", sortedIds);
        handleReorder(sortedIds);
      };
      const moveEventUp = (workout) => {
        // setPastedWorkouts([]);
        // setSelectedWorkouts([]);
        setEditWorkputData(workout);
        const sameDayWorkouts = workouts.filter(
          (w) => w.workout_day === workout.workout_day
        );
        const workoutIndex = sameDayWorkouts.findIndex(
          (w) => w._id === workout._id
        );
        if (workoutIndex > 0) {
          const newWorkouts = [...workouts];
          const prevWorkout = sameDayWorkouts[workoutIndex - 1];
          const currentWorkoutIndex = workouts.findIndex(
            (w) => w._id === workout._id
          );
          const prevWorkoutIndex = workouts.findIndex(
            (w) => w._id === prevWorkout._id
          );
          newWorkouts[currentWorkoutIndex] = prevWorkout;
          newWorkouts[prevWorkoutIndex] = workout;
          setworkouts(newWorkouts);
          updateSortedIds(workout.workout_day, newWorkouts);
        }
      };

      const moveEventDown = (workout) => {
        // setPastedWorkouts([]);
        // setSelectedWorkouts([]);
        setEditWorkputData(workout);

        const sameDayWorkouts = workouts.filter(
          (w) => w.workout_day === workout.workout_day
        );
        const workoutIndex = sameDayWorkouts.findIndex(
          (w) => w._id === workout._id
        );
        if (workoutIndex < sameDayWorkouts.length - 1) {
          const newWorkouts = [...workouts];
          const nextWorkout = sameDayWorkouts[workoutIndex + 1];
          const currentWorkoutIndex = workouts.findIndex(
            (w) => w._id === workout._id
          );
          const nextWorkoutIndex = workouts.findIndex(
            (w) => w._id === nextWorkout._id
          );
          newWorkouts[currentWorkoutIndex] = nextWorkout;
          newWorkouts[nextWorkoutIndex] = workout;
          setworkouts(newWorkouts);
          updateSortedIds(workout.workout_day, newWorkouts);
        }
      };
      const handleMouseLeave = () => {
        // Introduce a delay of 100 milliseconds before updating state on hover out
        const delay = 300;
        timeoutRef.current = setTimeout(() => {
          setIsHovered(false);
        }, delay);
      };

      return (
        <div
          className="calendar-workout-card my-4"
          style={{
            boxShadow: isWorkoutSelected(data)
              ? "-2px -2px 7px 1px var(--portal-theme-primary), 1px 1px 7px 2px var(--portal-theme-primary)"
              : "",
            border:
              editWorkputData?._id == data?._id
                ? "2px solid var(--portal-theme-primary)"
                : "0px solid var(--portal-theme-primary)",
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="w-100 h-100 " onMouseEnter={handleMouseEnter}>
            {isHovered && (
              <div className="all-icon-calendar-workout-general d-flex w-100 justify-content-between">
                <div className="d-flex">
                  <Tooltip
                    title={`${
                      data?.workout_loggers.length == 0
                        ? "Delete"
                        : "Can't be deleted, This workout has results."
                    }`}
                  >
                    <DeleteIcon
                      className={`${
                        data?.workout_loggers.length == 0
                          ? "del-icon-calendar-workout-general "
                          : "del-icon-calendar-workout-disabled-general"
                      }`}
                      onClick={
                        data?.workout_loggers.length == 0
                          ? () => handleAgreeDelete(data)
                          : () => {}
                      }
                    />
                  </Tooltip>
                  <Tooltip title={`${"Copy"}`}>
                    <div
                      className={`${"copy-icon-calendar-workout-general ms-1 d-flex justify-content-center align-items-center"}`}
                    >
                      <ContentCopyIcon
                        sx={{ width: 17, height: 17 }}
                        onClick={() =>
                          handleCopyExercise(data, rowIndex, colIndex)
                        }
                      />
                    </div>
                  </Tooltip>
                </div>
                <div className="d-flex">
                  {" "}
                  <Tooltip title="Move Up">
                    <div className="arrow-icon-calendar-workout-general ms-1 d-flex justify-content-center align-items-center">
                      <IconButton disabled={isFirstEventOnDate(data)}>
                        <KeyboardArrowUpIcon
                          sx={{ width: 17, height: 17 }}
                          onClick={() => moveEventUp(data)}
                        />
                      </IconButton>
                    </div>
                  </Tooltip>{" "}
                  <Tooltip title="Move Down">
                    <div className="arrow-icon-calendar-workout-general ms-1 d-flex justify-content-center align-items-center">
                      <IconButton disabled={isLastEventOnDate(data)}>
                        <KeyboardArrowDownIcon
                          sx={{ width: 17, height: 17 }}
                          onClick={() => moveEventDown(data)}
                        />
                      </IconButton>
                    </div>
                  </Tooltip>{" "}
                </div>
              </div>
            )}
          </div>

          <div onMouseEnter={handleMouseEnter}>
            <div
              className="calendar-workout-card-title"
              style={{
                backgroundColor: data?.program_info?.color,
              }}
              onMouseEnter={handleMouseEnter}
            >
              <div
                onMouseEnter={handleMouseEnter}
                className="calendar-workout-card-program"
              >
                {data?.program_info?.title}
              </div>
              <div className="d-flex justify-content-between">
                <div
                  className="calendar-workout-card-workout"
                  onClick={() => handleUpdateIteration(data)}
                  onMouseEnter={handleMouseEnter}
                >
                  {data?.title}
                </div>
                <div className="d-flex align-items-center">
                  <Icon
                    icon={
                      data.practice_type == "workout"
                        ? "mdi:gym"
                        : data.practice_type == "food"
                        ? "fluent:food-24-filled"
                        : data.practice_type == "mindset"
                        ? "flowbite:brain-outline"
                        : data.practice_type == "video"
                        ? "lets-icons:video-fill"
                        : ""
                    }
                    className="workout-type-icon-container "
                  />
                  {!data?.open ? (
                    <KeyboardArrowDownIcon
                      sx={{
                        cursor: "pointer",
                        opacity: 0.7,
                        fontSize: "20px",
                      }}
                      onClick={() => {
                        const list = [...workouts];
                        list[index]["open"] = true;
                        setData(list);
                      }}
                    />
                  ) : (
                    <KeyboardArrowUpIcon
                      sx={{
                        cursor: "pointer",
                        opacity: 0.7,
                        fontSize: "20px",
                      }}
                      onClick={() => {
                        const list = [...workouts];
                        list[index]["open"] = false;
                        setData(list);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            {data?.open && (
              <div className="calendar-workout-card-exercises">
                <div
                  className="calendar-workout-card-container"
                  onMouseEnter={handleMouseEnter}
                  onClick={() => handleUpdateIteration(data)}
                >
                  {/* {data?.exercises.map((exercise) => {
                    return (
                      <div
                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                      >
                        {exercise?.title}
                      </div>
                    );
                  })} */}
                  {data?.practice_type == "video" ? (
                    <>
                      <div className="inner-div workout-calendar-exercise-card my-1">
                        {data?.dumyImage ? (
                          <div
                            style={{
                              whiteSpace: "break-spaces",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              className="image-border"
                              src={data?.dumyImage}
                              height="50"
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              whiteSpace: "break-spaces",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              className="image-border"
                              src={
                                s3baseUrl + data?.video_thumbnail?.thumbnail_1
                              }
                              height="50"
                            />
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {data?.exercises.map((exercise, index) => {
                    if (exercise?.exercise_type == "something_else") {
                      return (
                        <div className="inner-div workout-calendar-exercise-card my-1">
                          <div
                            style={{
                              whiteSpace: "break-spaces",
                            }}
                          >
                            {exercise?.title}
                          </div>
                        </div>
                      );
                    } else if (
                      exercise?.exercise_type == "amrap" &&
                      exercise?.emom_amrap &&
                      exercise?.emom_amrap.length > 0
                    ) {
                      return (
                        <div className="workout-calendar-Superset-card inner-div my-1">
                          <div className="text-center">AMRAP</div>
                          {exercise?.emom_amrap?.map(
                            (interval, intervalIndex) => {
                              return (
                                <div className="my-1">
                                  <div style={{ fontSize: "8px" }}>
                                    Movements
                                  </div>
                                  {interval?.map((exerData) => {
                                    return (
                                      <div
                                        className="inner-div"
                                        style={{
                                          whiteSpace: "break-spaces",
                                          marginBottom: "3px",
                                          // marginBottom: "1px",
                                          // backgroundColor:
                                          //   "rgba(255, 255, 255,255)",
                                          // overflow: "hidden",
                                          // textOverflow: "ellipsis",
                                        }}
                                      >
                                        {exerData?.exercise_info?.title}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    } else if (
                      exercise?.exercise_type == "emom" &&
                      exercise?.emom_amrap &&
                      exercise?.emom_amrap.length > 0
                    ) {
                      return (
                        <div className="workout-calendar-Superset-card inner-div my-1">
                          <div className="text-center">EMOM</div>
                          {exercise?.emom_amrap?.map(
                            (interval, intervalIndex) => {
                              return (
                                <div className="my-1">
                                  <div style={{ fontSize: "8px" }}>
                                    Minute{" "}
                                    {getMinutesForInterval(
                                      index,
                                      intervalIndex
                                    )}
                                  </div>
                                  {interval?.map((exerData) => {
                                    return (
                                      <div
                                        className="inner-div"
                                        style={{
                                          whiteSpace: "break-spaces",
                                          marginBottom: "3px",
                                          // marginBottom: "1px",
                                          // backgroundColor:
                                          //   "rgba(255, 255, 255,255)",
                                          // overflow: "hidden",
                                          // textOverflow: "ellipsis",
                                        }}
                                      >
                                        {exerData?.exercise_info?.title}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    } else if (
                      exercise?.sub_exercises &&
                      exercise?.sub_exercises.length > 0
                    ) {
                      return (
                        <div className="workout-calendar-Superset-card inner-div my-1">
                          {exercise?.sub_exercises?.map(
                            (exerData, exerIndex) => {
                              return (
                                <div
                                  className="inner-div"
                                  style={{
                                    whiteSpace: "break-spaces",
                                    marginBottom: "3px",
                                    // overflow: "hidden",
                                    // textOverflow: "ellipsis",
                                  }}
                                >
                                  {exerData?.exercise_info?.title}
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    } else
                      return (
                        <div className="inner-div workout-calendar-exercise-card my-1">
                          <div
                            style={{
                              whiteSpace: "break-spaces",
                            }}
                          >
                            {exercise?.exercise_info?.title}
                          </div>
                        </div>
                      );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    };
    const getWorkoutListing = async () => {
      // setIsLoading(true);
      let postData = {
        start_date: 0,
        end_date: parseInt(state?.no_of_weeks) * 7,
        slug: slug,
      };

      const result = await programme_workout_list_api(postData);
      if (result.code === 200) {
        const modifiedArray = result?.program_workouts.map((item) => {
          return {
            ...item,
            open: true,
            editable: item?.workout_loggers.length > 0 ? false : true,
          };
        });
        setworkouts(modifiedArray);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    };

    useEffect(() => {
      getWorkoutListing();
    }, []);

    // useEffect(() => {
    //   const timeoutId = setTimeout(() => {
    //     setDataCopy("");
    //   }, 15000);

    //   return () => clearTimeout(timeoutId);
    // }, []);
    const closeDragConfig = async (value) => {
      setOpenDragConfig(false);
      getWorkoutListing();
    };
    const closeCopyPasteConfig = async (value) => {
      setPastedWorkouts([]);
      setSelectedWorkouts([]);
      setOpenCopyPasteConfig(false);
      setDataCopy(false);
      getWorkoutListing();
      // reloadList();
    };
    const handleCopyPaste = async (value) => {
      setOpenCopyPasteConfig(false);
      const workoutsIds = selectedWorkouts.map((workout) => workout._id);

      const formDataObject = {
        copiedWorkoutIds: workoutsIds,
        workout_copy_type: "limited",
        targetStartDay: pasteDate,
      };

      console.log(formDataObject, "formDataObject");
      const result = await paste_multiple_workouts(formDataObject);
      if (result.code === 200) {
        setPastedWorkouts(result?.copiedWorkouts);
        setSelectedWorkouts([]);
        enqueueSnackbar(result.message, { variant: "success" });
        setDataCopy(false);
        const workout = result?.copiedWorkouts.map((newWorkout, i) => {
          return {
            ...newWorkout,
            title: newWorkout.title,
            color: newWorkout.program_info?.color,
            workout_color: newWorkout.program_info?.color,
            workout_title: newWorkout.title,
            workout_exercises: newWorkout.exercises,
            workout_program: newWorkout.program_info,
            status: newWorkout.status,
            is_deleteAble:
              newWorkout?.workout_loggers.length > 0 ? false : true,
            date: moment(newWorkout.workout_date).format("YYYY-MM-DD"),
            index: workouts.length + i,
            open: true,
            editable: newWorkout?.workout_loggers.length > 0 ? false : true,
          };
        });
        let list = [...workouts, ...workout];
        setworkouts(list);
      } else {
        setSelectedWorkouts([]);
        enqueueSnackbar(result.message, { variant: "error" });
        setDataCopy(false);
      }
    };
    const handleDraged = async (value) => {
      setOpenDragConfig(false);
      console.log(DragConfigDate, DragConfigData, "dlkjfkdsjfakslja");
      const formDataObject = {
        title: DragConfigData?.title,
        description: DragConfigData?.description,
        exercises: DragConfigData?.exercises,
        workout_type: "",
        status: true,
        workout_day: DragConfigDate,
        video_url: DragConfigData?.video_url,
        video_thumbnail: DragConfigData?.video_thumbnail,
      };
      const formData = new FormData();

      formData.append("title", formDataObject.title);
      formData.append("description", formDataObject.description);
      formData.append("exercises", JSON.stringify(formDataObject.exercises));
      formData.append("workout_type", formDataObject.workout_type);
      formData.append("status", formDataObject.status);
      formData.append("workout_day", formDataObject.workout_day);
      formData.append(
        "video_url",
        formDataObject.video_url ? formDataObject.video_url : ""
      );
      if (formDataObject.video_thumbnail) {
        formData.append(
          "duplicate_image_path",
          JSON.stringify(formDataObject.video_thumbnail)
        );
      }
      console.log(formDataObject, "dslfkdjsaa");
      const result = await update_program_workout_api(
        formData,
        DragConfigData?.program_workout_slug
      );

      if (result.code === 200) {
        setEditWorkputData(DragConfigData);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        getWorkoutListing();
      }
    };
    const drag = (event, data) => {
      event.dataTransfer.setData("text/plain", JSON.stringify(data));
      event.target.style.opacity = "0.01";
    };
    const dragEnd = (event, data) => {
      event.dataTransfer.setData("text/plain", JSON.stringify(data));
      event.target.style.opacity = "";
    };
    const drop = (event, rowIndex, colIndex) => {
      event.preventDefault();
      const data = JSON.parse(event.dataTransfer.getData("text/plain"));
      const modifiedArray = workouts.map((item) => {
        if (item._id === data?._id) {
          return { ...item, workout_day: rowIndex * 7 + (colIndex + 1) };
        }
        return item;
      });
      setworkouts(modifiedArray);
      setOpenDragConfig(true);
      setDragConfigDate(rowIndex * 7 + (colIndex + 1));
      setDragConfigData(data);
    };

    function getNewCells(selectedCells, hoveredCell) {
      const cellDifferences = [];
      const maxCols = 7;

      for (let i = 1; i < selectedCells.length; i++) {
        const prevCell = selectedCells[i - 1];
        const currCell = selectedCells[i];

        const diffRow = currCell.rowIndex - prevCell.rowIndex;
        const diffCol = currCell.colIndex - prevCell.colIndex;

        cellDifferences.push({ rowDiff: diffRow, colDiff: diffCol });
      }

      const newCells = [
        { rowIndex: hoveredCell.rowIndex, colIndex: hoveredCell.colIndex },
      ];

      for (let diff of cellDifferences) {
        const lastCell = newCells[newCells.length - 1];

        let nextRowIndex = lastCell.rowIndex + diff.rowDiff;
        let nextColIndex = lastCell.colIndex + diff.colDiff;

        if (nextColIndex >= maxCols) {
          nextRowIndex += Math.floor(nextColIndex / maxCols);
          nextColIndex = nextColIndex % maxCols;
        } else if (nextColIndex < 0) {
          nextRowIndex -= Math.ceil(Math.abs(nextColIndex) / maxCols);
          nextColIndex = (maxCols + (nextColIndex % maxCols)) % maxCols;
        }

        if (
          nextColIndex === 0 &&
          newCells.some((cell) => cell.rowIndex === nextRowIndex)
        ) {
          nextColIndex = maxCols;
          nextRowIndex--;
        }

        newCells.push({ rowIndex: nextRowIndex, colIndex: nextColIndex });
      }

      return newCells;
    }

    useEffect(() => {
      if (selectedWorkouts.length > 0 && hoveredCell) {
        let selected_Cells = selectedWorkouts.map((workout) => {
          return {
            rowIndex: workout.rowIndex,
            colIndex: workout.colIndex,
          };
        });

        const newCells = getNewCells(selected_Cells, hoveredCell);
        document.querySelectorAll(".fixed-table-data").forEach((cell) => {
          cell.classList.remove("highlighted-hover");
        });
        if (newCells.length > 0) {
          document.querySelectorAll(".fixed-table-data").forEach((cell) => {
            const rowIndex = cell.getAttribute("data-row");
            const colIndex = cell.getAttribute("data-col");

            if (
              newCells.some(
                (cell) => cell.rowIndex == rowIndex && cell.colIndex == colIndex
              )
            ) {
              cell.classList.add("highlighted-hover");
            }
          });
        }
      } else {
        document.querySelectorAll(".fixed-table-data").forEach((cell) => {
          cell.classList.remove("highlighted-hover");
        });
      }
    }, [selectedWorkouts, hoveredCell]);

    if (isLoading === true) {
      return <CircularProgress className={classes.loading} color="primary" />;
    }
    return (
      <div className="container">
        {drawerState && (
          <AddWorkoutPopup
            open={drawerState}
            setOpen={setDrawerState}
            day={selectedDate}
            programme={state}
            reloadList={getWorkoutListing}
            setEditWorkputData={setEditWorkputData}
            events={workouts}
            setEvents={setworkouts}
          />
        )}

        {editItrationDrawerState && (
          <EditWorkoutPopup
            open={editItrationDrawerState}
            setOpen={setEditItrationDrawerState}
            day={selectedDate}
            data={editWorkputData}
            programme={state}
            reloadList={getWorkoutListing}
            events={workouts}
            setEvents={setworkouts}
          />
        )}
        <ConfirmationWithDescription
          open={openDelete}
          setOpen={setOpenDelete}
          title={"Are you sure you want to Delete?"}
          descriotion={`If you take action it will delete from ${effectedMembers} users.`}
          handleAgree={handleDelete}
        />
        {openDragConfig && (
          <ConfirmationWithDescription
            open={openDragConfig}
            setOpen={closeDragConfig}
            title={"Do you want to take this action?"}
            descriotion={`It will impact ${effectedMembers} users, who has access to this program.`}
            handleAgree={handleDraged}
          />
        )}
        {openCopyPasteConfig && (
          <ConfirmationWithDescription
            open={openCopyPasteConfig}
            setOpen={closeCopyPasteConfig}
            title={"Do you want to paste it here?"}
            descriotion={`It will impact ${effectedMembers} users, who has access to this program.`}
            handleAgree={handleCopyPaste}
          />
        )}
        <>
          <div
            style={{
              justifyContent: "end",
              display: "flex",
              position: "relative",
            }}
          >
            <button
              className="small-contained-button"
              style={{ position: "absolute", right: 0, bottom: 70 }}
              type="button"
              onClick={handleOpen}
            >
              Notepad
            </button>
          </div>
          <Dialog
            open={open}
            disableEnforceFocus={true}
            onClose={handleClose}
            maxWidth={false} // Disable maxWidth to allow full control over width
            fullWidth
            sx={{
              "& .MuiDialog-paper": {
                width: "50vw",
                maxWidth: "none",
                height: "90vh",
              },
            }}
          >
            <div className="close_custom_icon" onClick={handleClose}>
              <CloseIcon
                style={{
                  fill: "var(--portal-theme-primary)",
                  fontSize: 18,
                  marginTop: 6,
                }}
              />
            </div>
            <DialogTitle sx={{ textAlign: "center" }}>
              {" "}
              Coach note for {state.title}{" "}
            </DialogTitle>
            <DialogContent
              sx={{
                height: "calc(100% - 64px)",
                overflowY: "auto",
              }}
            >
              <div className="editor-container">
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  name="description"
                  editorHeight={450}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleAddNote}
                color="primary"
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </>

        <table className="fixedTable" id="dragTable">
          <thead>
            <tr>
              {[...Array(numCols)].map((_, index) => (
                <th className="fixed-table-head" key={index}>
                  <div className="mt-2 mb-2" style={{ opacity: 0.7 }}>
                    Day {index + 1}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex} className="">
                {row.map((col, colIndex) => (
                  <td
                    className="fixed-table-data"
                    key={colIndex}
                    data-row={rowIndex}
                    data-col={colIndex}
                    onMouseEnter={() => handleCellHover(rowIndex, colIndex)}
                    onMouseLeave={handleCellLeave}
                    onDrop={(event) => drop(event, rowIndex, colIndex)}
                    onDragOver={(event) => event.preventDefault()}
                  >
                    <div
                      style={{
                        position: "relative",
                        height: "100%",
                        width: "100%",
                        marginTop: 15,
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "600",
                          ml: 0.5,
                          mt: 2,
                          opacity: 0.6,
                        }}
                        variant="body2"
                      >
                        Week {rowIndex + 1}
                      </Typography>
                      <div style={{ minHeight: "100px" }}>
                        {hoveredCell &&
                          hoveredCell.rowIndex === rowIndex &&
                          hoveredCell.colIndex === colIndex && (
                            <div className="cell-icon-workout d-flex text-end w-100">
                              {dataCopy && (
                                <Tooltip
                                  touch="on"
                                  placement="bottom"
                                  title={"Paste"}
                                >
                                  <div className="paste-icon-workout-general me-1">
                                    <AssignmentTurnedInIcon
                                      onClick={() =>
                                        handlePaste(rowIndex + 1, colIndex + 1)
                                      }
                                      sx={{
                                        cursor: "pointer",
                                        height: 15,
                                        width: 15,
                                        mb: "2px",
                                        mr: "2px",
                                      }}
                                    />
                                  </div>
                                </Tooltip>
                              )}

                              <AddIcon
                                className="add-icon-workout-general"
                                onClick={() =>
                                  handleOpenDrawer(rowIndex + 1, colIndex + 1)
                                }
                                sx={{
                                  mr: 0.5,
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          )}
                        {workouts.map((data, i) => {
                          if (
                            data?.workout_day ==
                            rowIndex * 7 + (colIndex + 1)
                          )
                            return (
                              <div
                                className="m-1"
                                style={{ position: "relative" }}
                                id={data.id + colIndex}
                                class="draggable"
                                draggable={data.editable}
                                onDragStart={(event) =>
                                  data.editable &&
                                  drag(
                                    event,
                                    data /* Pass the data you want to drag */
                                  )
                                }
                                onDragEnd={(event) =>
                                  data.editable && dragEnd(event, data)
                                }
                              >
                                <EventCard
                                  rowIndex={rowIndex}
                                  colIndex={colIndex}
                                  data={data}
                                  index={i}
                                  setData={setworkouts}
                                  onDelete={handleDeleteEvent}
                                />
                              </div>
                            );
                        })}
                      </div>
                    </div>

                    {/* <div>Week {colIndex + 1}</div> */}
                    {/* <input
                  type="text"
                  value={col}
                  onChange={(e) =>
                    handleChange(rowIndex, colIndex, e.target.value)
                  }
                /> */}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div>
      <div className="container mb-2">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <div className="row">
            <h2>{state?.title}</h2>
          </div>
        </div>
      </div>
      <Table numRows={parseInt(state?.no_of_weeks)} numCols={7} />
    </div>
  );
};

export default DynamicTable;
