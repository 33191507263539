import React, { useEffect, useState } from "react";

import Modal from "@mui/material/Modal";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
} from "@mui/material";

import { FormControlLabel, Box, Checkbox, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useContentSetting } from "src/Hooks/ContentSettingState";

import { get_root_value } from "src/utils/domUtils";

function ParameterModel({ open, setOpen, data, handleChange, type }) {
  const [isVdot, setIsVdot] = useState(false);
  const { userInfo } = useContentSetting();
  const handleClose = () => {
    setOpen(false);
  };
  const [fields, setFields] = useState([]);
  // const fields =

  const handleTagToggle = (tag) => {
    let newSelectedTags = [...data];

    const index = newSelectedTags.indexOf(tag);
    if (index === -1) {
      if (tag === "Vdot") {
        newSelectedTags = ["Vdot", "Distance", "Time"];
      } else newSelectedTags.push(tag);
      if (tag === "Pace") {
        newSelectedTags = ["Pace"];
      } else newSelectedTags.push(tag);
    } else {
      if (tag === "Vdot" || tag === "Pace") {
        newSelectedTags = ["Weight", "Distance/Calories", "Rest"];
      } else newSelectedTags.splice(index, 1);
    }
    handleChange(newSelectedTags);
    // setSelectedFields(newSelectedTags);
  };
  useEffect(() => {
    setFields(
      type == "VDOT"
        ? [
            "Duration/Pace",
            "Reps",
            "Height",
            "Weight",
            "Distance/Calories",
            "Rest",
            "Distance",
            "Time",
            "%MHR",
            "Tempo",
            "Vdot",
            "Pace",
          ]
        : [
            "Duration/Pace",
            "Reps",
            "Height",
            "Weight",
            "Distance/Calories",
            "Rest",
            "Time",
            "%MHR",
            "Tempo",
            "Pace",
          ]
    );
  }, [type]);
  useEffect(() => {
    if (open) {
      if (!userInfo.vdot_access && type === "VDOT") {
        const vdotIndex = fields.indexOf("Vdot");
        if (vdotIndex !== -1) {
          setFields([
            "Duration/Pace",
            "Reps",
            "Height",
            "Weight",
            "Distance/Calories",
            "Rest",
            "Time",
            "%MHR",
            "Tempo",
            "Pace",
          ]);
        }
      }
      if (data.includes("Vdot")) {
        setIsVdot(true);
      }
    }
    return () => {
      setIsVdot(false);
    };
  }, [open]);
  useEffect(() => {
    console.log(type, "sdjkhjaf", isVdot);
    if (isVdot && type === "VDOT") {
      setFields([
        "Duration/Pace",
        "Reps",
        "Height",
        "Weight",
        "Distance/Calories",
        "Rest",
        "Distance",
        "Time",
        "%MHR",
        "Tempo",

        "Vdot",
        "Pace",
      ]);

      return;
    } else {
      console.log(userInfo.vdot_access, "sdjkhjaf", userInfo);
      if (!userInfo.vdot_access && type === "VDOT") {
        const vdotIndex = fields.indexOf("Vdot");
        if (vdotIndex !== -1) {
          setFields([
            "Duration/Pace",
            "Reps",
            "Height",
            "Weight",
            "Distance/Calories",
            "Rest",
            "Time",
            "%MHR",
            "Tempo",
            "Pace",
          ]);
        }
      }
    }
  }, [isVdot]);
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        <div
          className={`text-end mt-1 d-flex justify-content-between align-items-center`}
        >
          <DialogTitle
            sx={{
              paddingY: 0,
              color: get_root_value("--portal-theme-primary"),
            }}
          >
            Parameters
          </DialogTitle>

          <IconButton
            className="back-screen-button me-1 "
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {/* <DialogTitle>
          <CloseIcon onClick={handleClose} />
        </DialogTitle> */}
        <DialogContent>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="row">
                {fields.map(
                  (tag) =>
                    // Exclude "Distance/Calories" and "Duration/Pace" if "VDOT" is selected
                    !(
                      (data.includes("Vdot") || data.includes("Pace")) &&
                      (tag === "Distance/Calories" || tag === "Duration/Pace")
                    ) &&
                    // Exclude "Distance" and "Time" if "Vdot" is not selected
                    !(
                      data.includes("Vdot") == false &&
                      data.includes("Pace") == false &&
                      tag === "Time"
                    ) &&
                    !(
                      data.includes("Vdot") === false && tag === "Distance"
                    ) && (
                      <div className="col-lg-6 col-md-6 col-sm-12" key={tag}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Checkbox
                            size="small"
                            checked={data.includes(tag)}
                            onChange={() => handleTagToggle(tag)}
                            sx={{ padding: "5px" }}
                          />
                          <FormControlLabel
                            control={
                              <Typography
                                variant="body2"
                                sx={{ marginLeft: 1 }}
                              >
                                {tag === "Vdot" ? "Auto Pacer" : tag}
                              </Typography>
                            }
                            label=""
                          />
                        </Box>
                      </div>
                    )
                )}
              </div>
            </DialogContentText>
          </DialogContent>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ParameterModel;
